<template>
  <div class="container">
    <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      v-if="loading"
      size="24px"
      >加载中...</van-loading
    >
    <div v-if="loading == false">
      <div class="detail">
        <h2 style="text-align: center" v-if="this.type != 104">
          {{ articles.title }}
        </h2>
        <VideoAudioPlayer :courses="articles"></VideoAudioPlayer>
        <div class="acontent" v-html="articles.detailsHtml"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getArticleInfoPlus } from "@/api/article";
import { getArticleType } from "@/utils/datetype";
import VideoAudioPlayer from "../components/videoAudioPlayer";
export default {
  name: "znarticledetail",
  data() {
    return {
      loading: true,
      type: 100,
      title: "",
      articles: {},

      CommentPage: {
        current: 1,
        pageSize: 10,
        filterData: {
          itemId: "",
          itemType: 1,
        },
      },
    };
  },
  components: {
    VideoAudioPlayer,
  },
  updated() {
    this.addpreview();
  },
  created() {
    this.initData();
    this.onLoad();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.path == "/article/detail") {
        if (to.query != from.query) {
          this.loading = true;
          this.initData();
          this.onLoad();
        }
      }
    },
  },
  mounted() {},

  methods: {
    initData() {
      let type = this.$route.query.t;
      this.type = type;
      this.title = getArticleType(type);
    },
    // toProduct(item) {
    //   this.$router.push({
    //     path: "/article",
    //     query: { type: item.typeId, productId: item.productId },
    //   });
    // },
    async onLoad() {
      let para = this.$route.query.p;
      let aresult = await getArticleInfoPlus(para); //({ ...this.page })
      console.log(aresult);
      if (aresult.data.success) {
        this.articles = aresult.data.data;
        if (this.type == 104 || this.type == 108) {
          this.title = this.articles.title;
        }
        document.title = this.articles.title;
        this.CommentPage.filterData.itemId = this.articles.id;
        this.loading = false;
      }
      else{
        Toast(aresult.data.errorMessage);
        this.loading = false;
      }
    },
    //加图片预览
    addpreview() {
      var listdiv = document.getElementsByClassName("acontent");
      listdiv.forEach((acontentdiv) => {
        var imgs = acontentdiv.getElementsByTagName("img");

        imgs.forEach((element) => {
          element.setAttribute("preview", this.articles.id);
        });
      });
      this.$previewRefresh();
    },
  },
};
</script>


<style lang="less" scoped>
.loading {
  text-align: center;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

.detail {
  padding: 10px 10px 20px;
}

.acontent {
  /deep/ img {
    max-width: 100% !important;
    display: block;
    border: 0;
  }
  /deep/ video {
    max-width: 100% !important;
  }
  /deep/  p {
    padding: revert;
    margin: revert;
}
}
</style>