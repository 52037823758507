import request from '@/utils/request'

export function AddBrowsingLog(params) {
    return request({
        url: '/api/BrowsingLog/Add',
        data:  { ...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
        },

    })
}
///api/BrowsingLog/Cancel
export function CancelBrowsingLog(params) {
    return request({
        url: '/api/BrowsingLog/Cancel',
        data:  { ...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
        },

    })
}

export function QueryBrowsingLog(params) {
    return request({
        url: '/api/BrowsingLog/Get',
        data:  { ...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
        },

    })
}

export function getBrowsingLogTypeList(params) {
    return request({
        url: '/api/BrowsingLog/PageItemType',
        data: { ...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
        },

    })
}

export function getBrowsingLogList(params) {
    return request({
        url: '/api/BrowsingLog/List',
        data: { ...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
        },

    })
}

//已读用户数据
export function getBrosingLogUserList(params) {
    return request({
      url: 'api/BrowsingLog/GetBrosingLogUserList',
      params: { ...params },
      method: 'get',
      header: {
        'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
      },
  
    })
  }